import { ViewPayload } from "@Terra/shared/widgets/interface";
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Injectable()
export class InsightSummaryFacade implements OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>();
  private readonly bannerPBStatusSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  bannerPBStatus$ = this.bannerPBStatusSub.asObservable().pipe(takeUntil(this.destroy$));
  private readonly getBetaSummaryChartStatusSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  getBetaSummaryChartStatus$ = this.getBetaSummaryChartStatusSub.asObservable().pipe(takeUntil(this.destroy$));
  private readonly getBetaSummaryTableStatusSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  getBetaSummaryTableStatus$ = this.getBetaSummaryTableStatusSub.asObservable().pipe(takeUntil(this.destroy$));
  private readonly getViewsSub: BehaviorSubject<ViewPayload> = new BehaviorSubject<ViewPayload>(null);
  getViews$ = this.getViewsSub.asObservable().pipe(takeUntil(this.destroy$));
  private readonly isAssetAvailableSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  isAssetAvailable$ = this.isAssetAvailableSub.asObservable().pipe(takeUntil(this.destroy$));
  setBannerStatus(bannerStatus: boolean): void {
    this.bannerPBStatusSub.next(bannerStatus);
  }

  setBetaSummaryTableStatus(status: boolean): void {
    this.getBetaSummaryTableStatusSub.next(status);
  }

  setBetaSummaryChartStatus(status: boolean): void {
    this.getBetaSummaryChartStatusSub.next(status);
  }

  saveView(value: ViewPayload): void {
    this.getViewsSub.next(value);
  }

  setAssetAvailable(state: boolean): void {
    this.isAssetAvailableSub.next(state);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}