import { Directive, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appCopyToClipBoard]'
})
export class CopyToClipBoardDirective implements OnChanges {
  @Input() textToCopy: string;
  @Output() copySuccess = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.textToCopy) {
      this.copyToClipBoard();
    }
  }

  copyToClipBoard(): void {
    if (this.textToCopy !== null && this.textToCopy !== undefined && this.textToCopy !== '') {
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        this.textToCopy = JSON.stringify(this.textToCopy);
        navigator.clipboard.writeText(this.textToCopy);
        this.copySuccess.emit();
      }
    }
  }
}
