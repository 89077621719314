import { AppState, EnvironmentState, getProductFamilyConfigSelector, seenErrorInProductFamilyConfig } from '@Terra/pitmanagement/app-store';
import { ProductFamilyConfig, ProductFamily as ProductFamilyDetails } from '@Terra/shared/widgets/interface';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PRODUCT_FAMILY_MAPPING, ProductFamily } from './assetimage/product-family.config';

@Injectable({
  providedIn: 'root'
})
export class DirectiveService implements OnDestroy {
  productFamilyConfig: ProductFamilyConfig;
  isProductFamilyDataAvailable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  productFamilyImgBaseUrl = '';
  subscriptions = new Subscription();
  assetTypeConfig = {};
  constructor(private readonly appStore: Store<AppState | EnvironmentState>) {
    this.getProductFamilyConfig();
    this.subscribeEnvironment();
  }

  getProductFamilyList(serialNo: string): ProductFamilyDetails[] {
    return this.productFamilyConfig[serialNo.substring(0, 3)];
  }

  getProductFamily(make: string, serialno: string): ProductFamily {
    let imageUrl: string;
    if (PRODUCT_FAMILY_MAPPING.CAT_ASSET_MAKE_LIST.includes(make)) {
      const productFamily = this.productFamilyConfig[serialno.slice(0, 3)];
      if (productFamily && productFamily[0] && productFamily[0].imageName) {
        imageUrl = `${this.productFamilyImgBaseUrl}${productFamily[0].imageName}`;
      } else {
        imageUrl = `${PRODUCT_FAMILY_MAPPING.IMAGE_PATH.replace('{path}', PRODUCT_FAMILY_MAPPING.DEFAULT_IMAGE.CAT)}`;
      }
    } else {
      imageUrl = `${PRODUCT_FAMILY_MAPPING.IMAGE_PATH.replace('{path}', PRODUCT_FAMILY_MAPPING.DEFAULT_IMAGE.NON_CAT)}`;
    }
    return { imageUrl };
  }

  checkProductFamilyBySerialNo(serialNo: string, productFamilyMappingList: string[]): boolean {
    if (serialNo && productFamilyMappingList?.length) {
      const productFamily = this.getProductFamilyList(serialNo);
      const newProductFamilyMappingList = new Set(productFamilyMappingList);
      if (productFamily?.length) {
        for (const product of productFamily) {
          if (newProductFamilyMappingList.has(product.productFamilyCode)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  getProductFamilyConfig(): void {
    this.subscriptions.add(
      this.appStore
        .select(seenErrorInProductFamilyConfig)
        .pipe(
          switchMap((isError: boolean) => {
            if (isError) {
              this.isProductFamilyDataAvailable.next(true);
              return of({});
            } else {
              return this.appStore.select(getProductFamilyConfigSelector);
            }
          })
        )
        .subscribe((productFamily: ProductFamilyConfig) => {
          this.productFamilyConfig = productFamily;
          if (Object.keys(this.productFamilyConfig).length) {
            this.isProductFamilyDataAvailable.next(true);
          }
        })
    );
  }
  subscribeEnvironment(): void {
    this.subscriptions.add(
      this.appStore
        .select((envState: EnvironmentState) => envState.environment)
        .subscribe(res => {
          if (res) this.productFamilyImgBaseUrl = res.productFamilyImgBaseUrl;
        })
    );
  }

  configureAssets(mappingList: Record<string, string[]>): void {
    this.assetTypeConfig = Object.keys(mappingList).reduce((acc, currentValue) => {
      mappingList[currentValue].forEach(data => {
        acc[data] = currentValue;
      });
      return acc;
    }, {});
  }

  getAssetTypeFromSerialNo(serialNo: string, mappingList: Record<string, string[]>): string {
    if (!Object.keys(this.assetTypeConfig).length) {
      this.configureAssets(mappingList);
    }

    const productFamilyCode = this.getProductFamilyList(serialNo);
    return productFamilyCode ? this.assetTypeConfig[productFamilyCode[0].productFamilyCode] : null;
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }
}
