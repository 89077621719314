import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AssetImageDirective } from './assetimage/asset-image.directive';
import { CopyToClipBoardDirective } from './copyToClipBoard/copy-to-clip-board.directive';
import { SharedChipDirective } from './sharedChips/shared-chips.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [AssetImageDirective, SharedChipDirective, CopyToClipBoardDirective],
  exports: [AssetImageDirective, SharedChipDirective, CopyToClipBoardDirective]
})
export class CPDirectiveModule {}
