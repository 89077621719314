import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[domClickDetectDirective]',
  standalone: true
})
export class DomClickDetectDirective {
  constructor(public elementRef: ElementRef) {}

  @Output() domClicked = new EventEmitter<boolean>();

  @HostListener('document:click', ['$event'])
  onGlobalClick(event: MouseEvent): void {
    if (
      !Array.from(this.elementRef.nativeElement.children).some(el => (el as HTMLElement).contains(event.target as unknown as HTMLElement))
    ) {
      this.domClicked.emit(true);
    }
  }
}
