import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DirectiveService } from '../directive.service';

@Directive({
  selector: '[appAssetImage]'
})
export class AssetImageDirective implements OnChanges {
  @Input() serialno: string;
  @Input() make: string;

  constructor(private readonly eleRef: ElementRef, private readonly directiveService: DirectiveService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.serialno && changes.serialno.currentValue) {
      const productFamily = this.directiveService.getProductFamily(this.make, this.serialno);
      const imageSource = productFamily.imageUrl;
      this.eleRef.nativeElement.setAttribute('src', imageSource);
    }
  }
}
