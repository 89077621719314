import { Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, Renderer2, SimpleChanges } from '@angular/core';
import { CHIP_CONFIG, CHIP_ELEMENTS, CONTAINER_MIN_WIDTH, DEFAULT_WIDTH, EXTRA_WIDTH, IMAGE_URL, SHIFT_CLASSES } from '../directive.config';

@Directive({
  selector: '[appSharedChip]'
})
export class SharedChipDirective implements OnChanges {
  @Input() chipsConfig = CHIP_CONFIG;
  @Input() moreBtnText?: string;
  @Output() chipRemoved = new EventEmitter();
  @Output() countClicked = new EventEmitter();
  totalWidth = 0;
  maxChips = 0;
  isMobile = false;

  //re-render the chips when window size changes to fit in the given space.
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.renderChips();
  }

  constructor(public el: ElementRef, private readonly renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chipsConfig) {
      this.renderChips();
    }
  }
  //Checks whether it is Tablet/Mobile and assign width accordingly and calls the main function updateChips.
  renderChips(): void {
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', '');
    const isTablet = window.innerWidth >= 768 && window.innerWidth < 1024;
    this.isMobile = window.innerWidth < 768;
    const actualWidth = this.el.nativeElement.offsetWidth
      ? this.el.nativeElement.offsetWidth
      : isTablet
      ? DEFAULT_WIDTH.tabletContainerWidth
      : DEFAULT_WIDTH.containerWidth;
    const chipContainerWidth = actualWidth - CONTAINER_MIN_WIDTH;
    this.updateChips(chipContainerWidth);
  }

  //deep copy chipsData if it is Mobile or isCountHidden is true
  displayChipData(): Array<any> {
    return this.isMobile || this.chipsConfig.isCountHidden ? [...this.chipsConfig.chipsData] : [];
  }
  //This is the main function to render the chips
  updateChips(chipContainerWidth): void {
    this.addClassToElement(this.el.nativeElement, CHIP_ELEMENTS.mainClass);
    //Add whole chip container class
    if (this.chipsConfig.chipsContainerClass) {
      this.addClassToElement(this.el.nativeElement, this.chipsConfig.chipsContainerClass);
    }
    const displayedChips = this.displayChipData();

    if (!this.isMobile && !this.chipsConfig.isCountHidden) {
      let remainingWidth = chipContainerWidth;

      //Find the width of each chip and push how many chips can be displayed on present width to displayedChips array
      this.chipsConfig.chipsData.forEach(chipData => {
        const chipWidth = this.calculateChipWidth(chipData.label ? chipData.label : chipData.name ? chipData.name : chipData);

        if (remainingWidth >= chipWidth) {
          displayedChips.push(chipData);
          remainingWidth -= chipWidth;
        }
      });
    }
    //Display chips that pushed to displayedChips array.
    this.displayChips(displayedChips);
    if (!this.isMobile && !this.chipsConfig.isCountHidden) {
      this.createDisplayText(displayedChips.length);
    }
  }

  displayChips(displayedChips: any[]): void {
    displayedChips.forEach(chipData => {
      const chipContainer = this.renderer.createElement(CHIP_ELEMENTS.span);

      this.addClassToElement(chipContainer, CHIP_ELEMENTS.parentClass);
      if (chipData.class) {
        this.addClassToElement(chipContainer, chipData.class);
      }
      if (this.chipsConfig.chipClass) {
        this.addClassToElement(chipContainer, this.chipsConfig.chipClass);
      }
      // if (chipData?.chipClickEnable) {
      //   this.renderer.listen(chipContainer, 'click', () => this.countClicked.emit());
      // }
      const chipElement = this.renderer.createElement(CHIP_ELEMENTS.span);
      this.addClassToElement(chipElement, CHIP_ELEMENTS.childClass);
      const chipTextContent = chipData.label ? chipData.label : chipData.name ? chipData.name : chipData;
      //To add shift class if Shifts are available to show round colors before chip name
      if (SHIFT_CLASSES[chipTextContent]) {
        this.addClassToElement(chipContainer, SHIFT_CLASSES[chipTextContent]);
      }
      chipElement.textContent = chipTextContent;

      const closeIcon = this.renderer.createElement(CHIP_ELEMENTS.span);
      this.addingCloseIcon(chipData, closeIcon);
      this.addTooltipToChip(chipContainer, chipTextContent);

      this.appendChildElements(chipContainer, chipElement);
      this.appendChildElements(chipContainer, closeIcon);
      this.appendChildElements(this.el.nativeElement, chipContainer);
    });
  }

  // Adds Tooltip to the chips;
  addTooltipToChip(chipContainer, chipTextContent) {
    if (this.chipsConfig.isChipInSlider && this.calculateChipWidth(chipTextContent) > 123) {
      const tooltip = this.renderer.createElement('span');
      tooltip.textContent = chipTextContent;
      this.renderer.listen(chipContainer, 'mouseleave', event => {
        this.removeChildFromElement(chipContainer, tooltip);
        this.removeClassFromElement(tooltip, 'chips-tooltip-display-block');
      });
      this.renderer.listen(chipContainer, 'mouseenter', event => {
        this.addClassToElement(tooltip, 'chips-tooltip-display-block');
        this.appendChildElements(chipContainer, tooltip);
      });
    }
  }

  //This is the function to calculate each chip width
  calculateChipWidth(label): number {
    const offScreenElement = this.renderer.createElement('div');
    this.renderer.setStyle(offScreenElement, 'position', 'absolute');
    this.renderer.setStyle(offScreenElement, 'visibility', 'hidden');
    this.renderer.setStyle(offScreenElement, 'white-space', 'nowrap');
    this.renderer.setStyle(offScreenElement, 'font', 'inherit');

    this.appendChildElements(this.el.nativeElement, offScreenElement);

    offScreenElement.textContent = label;
    const extraWidth =
      EXTRA_WIDTH.TWENTY_FOUR +
      (this.chipsConfig.isCrossIconHidden ? EXTRA_WIDTH.ZERO : EXTRA_WIDTH.TWENTY) +
      (SHIFT_CLASSES[label] ? EXTRA_WIDTH.TWENTY : EXTRA_WIDTH.ZERO);
    const chipWidth = offScreenElement.offsetWidth ? offScreenElement.offsetWidth + extraWidth : DEFAULT_WIDTH.chipWidth;
    this.renderer.removeChild(this.el.nativeElement, offScreenElement);

    if (this.chipsConfig.isChipInSlider && chipWidth > EXTRA_WIDTH.SLIDER_MAX_CHIP_WIDTH) {
      return EXTRA_WIDTH.SLIDER_MAX_CHIP_WIDTH;
    }

    return chipWidth;
  }

  //Add close Icon to each chip
  addingCloseIcon(chipText, closeIcon): void {
    if (!this.chipsConfig.isCrossIconHidden && !chipText?.isCrossIconHidden) {
      const imgElement = this.renderer.createElement('img');
      this.addClassToElement(imgElement, CHIP_ELEMENTS.closeClass);

      this.setAttributesToElement(imgElement, 'src', IMAGE_URL.normalImage);
      this.setAttributesToElement(imgElement, 'width', IMAGE_URL.width);
      this.setAttributesToElement(imgElement, 'height', IMAGE_URL.height);
      this.setAttributesToElement(imgElement, 'alt', IMAGE_URL.closeAlt);

      this.renderer.listen(imgElement, 'click', () => this.chipRemoved.emit(chipText));
      this.renderer.listen(imgElement, 'mouseleave', event => {
        this.setAttributesToElement(imgElement, 'src', IMAGE_URL.normalImage);
        this.setAttributesToElement(imgElement, 'alt', IMAGE_URL.closeAlt);
      });
      this.renderer.listen(imgElement, 'mouseenter', event => {
        this.setAttributesToElement(imgElement, 'src', IMAGE_URL.hoverImage);
        this.setAttributesToElement(imgElement, 'alt', IMAGE_URL.closeAlt);
      });

      this.appendChildElements(closeIcon, imgElement);
    }
  }

  //Functino to Show count of Chips that are not displayed
  createDisplayText(maxChips: number): void {
    const remainingChips = this.chipsConfig.chipsData.length - maxChips;
    if (remainingChips > 0) {
      const countElement = this.renderer.createElement(CHIP_ELEMENTS.span);
      if (this.moreBtnText && maxChips === 0) {
        this.prepareMoreButtonChip(remainingChips, countElement);
      } else {
        this.addClassToElement(countElement, CHIP_ELEMENTS.countClass);
        countElement.textContent = `(+${remainingChips})`;
      }
      this.renderer.listen(countElement, 'click', () => this.countClicked.emit());
      this.appendChildElements(this.el.nativeElement, countElement);
    }
  }

  removeClassFromElement(element, className): void {
    this.renderer.removeClass(element, className);
  }

  addClassToElement(element, className): void {
    this.renderer.addClass(element, className);
  }

  setAttributesToElement(element, params, value): void {
    this.renderer.setAttribute(element, params, value);
  }

  appendChildElements(element, value): void {
    this.renderer.appendChild(element, value);
  }

  removeChildFromElement(element, child) {
    this.renderer.removeChild(element, child);
  }

  prepareMoreButtonChip(remainingChipsCount: number, countElement: HTMLElement): void {
    this.addClassToElement(countElement, CHIP_ELEMENTS.parentClass);
    const chipElement = this.renderer.createElement(CHIP_ELEMENTS.span);
    this.addClassToElement(chipElement, CHIP_ELEMENTS.childClass);
    chipElement.textContent = `+${remainingChipsCount} ${this.moreBtnText}`;
    this.appendChildElements(countElement, chipElement);
  }
}
