import { Directive, ElementRef, Input, OnInit, Renderer2, RendererStyleFlags2 } from '@angular/core';
import { isDemoModeON } from '@Terra/pitmanagement/shared/utils';
import { AppHideDisableConfig, ELEMENT_CLASSES, OverrideClassProperties } from '../directive.config';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appDemoModeHideDisable]',
  standalone: true
})
export class HideDisableDirective implements OnInit {
  @Input() appDemoModeHideDisable: AppHideDisableConfig;
  isDemoModeOn: boolean;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.isDemoModeOn = isDemoModeON();
  }

  ngOnInit(): void {
    if (!this.isDemoModeOn) {
      return;
    }
    if (!this.appDemoModeHideDisable || Object.keys(this.appDemoModeHideDisable).length === 0) {
      //default behaviour when it is null/undefined/empty
      this.addClassToElement([ELEMENT_CLASSES.HIDE_ELEMENT_CLASS]);
    } else {
      this.setClassToElement();
      this.appDemoModeHideDisable.overRideClass && this.setOverriddenProperties();
    }
  }

  setClassToElement(): void {
    const classNames = [
      this.appDemoModeHideDisable.hide ? ELEMENT_CLASSES.HIDE_ELEMENT_CLASS : '',
      this.appDemoModeHideDisable.disable ? ELEMENT_CLASSES.DISABLE_ELEMENT_CLASS : '',
      this.appDemoModeHideDisable.visibility ? ELEMENT_CLASSES.HIDE_VISIBILITY_ELEMENT_CLASS : ''
    ].filter(Boolean);
    this.appDemoModeHideDisable.customClass && classNames.push(...this.appDemoModeHideDisable.customClass);
    this.addClassToElement(classNames);
  }

  addClassToElement(classNames: string[]): void {
    this.elementRef.nativeElement.classList.add(...classNames);
  }

  setOverriddenProperties(): void {
    this.appDemoModeHideDisable.overRideClass.forEach((properties: OverrideClassProperties) => {
      this.renderer.setStyle(this.elementRef.nativeElement, properties.propertyName, properties.value, RendererStyleFlags2.Important);
    });
  }
}
