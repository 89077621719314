export const PRODUCT_FAMILY_MAPPING = {
  IMAGE_PATH: '/assetImage/{path}.png',
  DEFAULT_IMAGE: {
    MAIN: 'asset-image',
    CAT: 'cat-default',
    NON_CAT: 'noncat-default'
  },
  CAT_ASSET_MAKE_LIST: ['CAT', 'Z88']
};

export interface ProductFamily {
  imageUrl: string;
}
