export const CONTAINER_MIN_WIDTH = 70;
export const DEFAULT_WIDTH = {
  containerWidth: 500,
  tabletContainerWidth: 200,
  chipWidth: 80
};
export const CHIP_ELEMENTS = {
  mainClass: 'shared-chips-class',
  parentClass: 'parent-chip-cls',
  childClass: 'chip-cls',
  closeClass: 'close-icon',
  countClass: 'count-cls',
  span: 'span'
};

export const IMAGE_URL = {
  normalImage: '/assetImage/chip-close-icon.svg',
  hoverImage: '/assetImage/chip-close-icon-hover.svg',
  closeAlt: 'çlose',
  width: '12',
  height: '12'
};

export const CHIP_CONFIG = {
  chipsData: [],
  isCrossIconHidden: false,
  isCountHidden: false,
  chipClass: '',
  chipsContainerClass: '',
  isChipInSlider: false
};

export const SHIFT_CLASSES = {
  'Shift 1': 'shift-1',
  'Shift 2': 'shift-2',
  'Shift 3': 'shift-3'
};

export const EXTRA_WIDTH = {
  TWENTY_FOUR: 24,
  ZERO: 0,
  TWENTY: 20,
  SLIDER_MAX_CHIP_WIDTH: 170
};

export const ELEMENT_CLASSES = {
  HIDE_ELEMENT_CLASS: 'hide-element-directive',
  DISABLE_ELEMENT_CLASS: 'disable-element-directive',
  HIDE_VISIBILITY_ELEMENT_CLASS: 'hide-visibility-element-directive'
};

export interface OverrideClassProperties {
  propertyName: string;
  value: string;
  priority: string;
}

export interface AppHideDisableConfig {
  hide?: boolean;
  disable?: boolean;
  visibility?: boolean;
  overRideClass?: OverrideClassProperties[];
  customClass?: string[];
}
